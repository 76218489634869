<template>
  <div class="container-fluid pb-5">
    <b-card class="af-card">
      <h2 class="page-title text-18 text-uppercase mb-4">Transactions</h2>
      <div class="bg-primary curved-edge">
        <b-row class="align-items-end justify-content-between text-white py-5 px-3">
          <b-col cols="9">
            <h3>₦ {{ balance | format-currency }}</h3>
            <p class="text-uppercase">Main Balance</p>
          </b-col>
          <!-- <b-col cols="3" class="text-right">
            <b-button variant="outline-primary">Withdrawal</b-button>
          </b-col> -->
        </b-row>
      </div>
      <b-row class="justify-content-between">
        <b-col cols="6" class="mt-4">
          <b-form-group class="af-input">
            <b-input-group class="mb-3">
              <b-form-input v-model="filter" class="af-input-field" required></b-form-input>
              <template v-slot:append>
                <b-input-group-text class="af-input-append">
                  <span class="icon-magnifier"></span>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="text-right mt-4">
          <select v-model="status" class="custom-select">
            <option value="" selected disabled>Select status</option>
            <option value="completed">Paid Transactions</option>
            <option value="pending">Pending Transactions</option>
            <option value="declined">Declined Transactions</option>
            <option value="failed">Failed Transactions</option>
          </select>
        </b-col>
        <b-col cols="3" class="text-right mt-4">
          <select v-model="type" class="custom-select">
            <option value="" selected disabled>Select Type</option>
            <option value="bank-transfer">Bank Transfer</option>
            <option value="airtime-top-up">Airtime Top Up</option>
            <option value="save">Fund wallet</option>
            <option value="receive">Receive</option>
            <option value="withdraw">Withdraw (User bank)</option>
            <option value="withdraw-other-bank">Withdraw (Other banks)</option>
            <option value="transfer">Transfer</option>
            <option value="data-purchase">Data Purchase</option>
            <option value="credit">Credit</option>
            <option value="debit">Debit</option>
            <option value="dstv-subscription">DSTV Subscription</option>
            <option value="gotv-subscription">GOTV Subscription</option>
            <option value="startimes-subscription">Startimes Subscription</option>
            <option value="electricity-subscription">Electricity Subscription</option>
            <option value="waec-pin-purchase">WAEC Pin Purchase</option>
            <option value="neco-pin-purchase">NECO Pin Purchase</option>
            <option value="spectranet-pin-purchase">Spectranet Pin Purchase</option>
          </select>
        </b-col>
      </b-row>
      <!-- table -->
      <b-table
        id="af-table"
        ref="table"
        :items="providerFunc"
        :current-page="currentPage"
        :per-page="perPage"
        :fields="fields"
        @row-selected="tableRowSelected"
        select-mode="single"
        hover
        responsive
        borderless
        show-empty
        selectable
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #empty>
          <p class="text-center text-muted">No transactions found</p>
        </template>

        <template v-slot:head()="data">
          <span class="text-uppercase font-weight-bold">{{ data.label }}</span>
        </template>

        <template v-slot:cell(user)="data">
          <div class="row align-items-center no-gutters">
            <div class="text-uppercase mr-2">
              <span class="initials">{{ computeInitials(data.value.name) }}</span>
            </div>
            <div>
              <p class="mb-0">{{ data.value.name }}</p>
              <p class="text-muted text-14 mb-0">USER</p>
            </div>
          </div>
        </template>

        <template v-slot:head(updatedAt)>
          <span class="text-uppercase font-weight-bold title">Time</span>
        </template>

        <template v-slot:head(createdAt)>
          <span class="text-uppercase font-weight-bold title">Date</span>
        </template>

        <template v-slot:head(type)>
          <span class="text-uppercase font-weight-bold title">Transaction</span>
        </template>

        <template v-slot:cell(type)="data">
          <span>{{ data.value | capitalize }}</span>
        </template>

        <template v-slot:head(reference)>
          <span class="text-uppercase font-weight-bold title">Reference</span>
        </template>

        <template v-slot:cell(reference)="data">
          <span>#{{ data.value }}</span>
        </template>

        <template v-slot:cell(createdAt)="data">
          <span class="text-muted">{{ formatDate(data.value) }}</span>
        </template>

        <template v-slot:cell(updatedAt)="data">
          <span class="text-muted">{{ formatTime(data.item.createdAt) }}</span>
        </template>

        <template v-slot:cell(status)="data">
          <span v-if="data.value === 'completed'" class="text-success">Completed</span>
          <span v-if="data.value === 'pending'" class="text-warning">Pending</span>
          <span v-if="data.value === 'declined'" class="text-danger">Declined</span>
          <span v-if="data.value === 'failed'" class="text-danger">Failed</span>
        </template>

        <template v-slot:cell(amount)="data">
          <span class="text-success font-weight-bold text-uppercase">₦{{ data.value | format-currency }}</span>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="right"
        hide-ellipsis
        hide-goto-end-buttons
        aria-controls="af-table"
      >
        <template v-slot:page="{ page, active }">
          <span class="active" v-if="active">{{ page }}</span>
          <span v-else>{{ page }}</span>
        </template>
      </b-pagination>
    </b-card>

    <!-- transaction modal -->
    <b-modal content-class="af-modal" v-model="transactionOpened" hide-header hide-footer centered>
      <p class="lead mb-4">Transaction details</p>
      <div class="row no-gutters justify-content-between">
        <p class="text-secondary">Type</p>
        <p class="text-uppercase font-weight-bold mb-3">{{ selectedTransaction.type | capitalize }}</p>
      </div>
      <div class="row no-gutters justify-content-between">
        <p class="text-secondary">Reference</p>
        <p class="text-uppercase font-weight-bold mb-3">#{{ selectedTransaction.reference }}</p>
      </div>
      <div class="row no-gutters justify-content-between">
        <p class="text-secondary">Amount</p>
        <p class="text-uppercase font-weight-bold mb-3">₦{{ selectedTransaction.amount }}</p>
      </div>
      <div class="row no-gutters justify-content-between">
        <p class="text-secondary">Date</p>
        <p class="text-uppercase font-weight-bold mb-3">{{ formatDate(selectedTransaction.createdAt) }}</p>
      </div>
      <div class="row no-gutters justify-content-between">
        <p class="text-secondary">Time</p>
        <p class="text-uppercase font-weight-bold mb-3">{{ formatTime(selectedTransaction.updatedAt) }}</p>
      </div>
      <div v-for="(item, index) in selectedTransaction.extras" :key="index">
        <div v-if="item.length">
          <div v-for="(val, i) in item" :key="i">
            <div v-for="(j, k) in val" :key="k" class="row no-gutters justify-content-between">
              <p class="text-secondary">{{ k | capitalize }}</p>
              <p class="text-uppercase font-weight-bold mb-3">{{ j }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(val, key) in item" :key="val" class="row no-gutters justify-content-between">
            <p class="text-secondary">{{ key | capitalize }}</p>
            <p class="text-uppercase font-weight-bold mb-3">{{ val }}</p>
          </div>
        </div>
      </div>
      <div class="row no-gutters justify-content-between">
        <p class="text-secondary">Status</p>
        <p :class="{ 'text-uppercase font-weight-bold mb-3': true,
                     'text-success': selectedTransaction.status === 'completed',
                     'text-warning': selectedTransaction.status === 'pending',
                     'text-danger': selectedTransaction.status === 'declined',
                     'text-danger': selectedTransaction.status === 'failed'
                  }"
        >
          {{ selectedTransaction.status }}
        </p>
      </div>
      <b-button @click="transactionOpened = false" variant="primary" class="w-100">
        Close
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { getTransactions } from '@/apis/transactions'

export default {
  metaInfo: {
    title: 'Transactions',
    titleTemplate: '%s | AirtimeFlip'
  },
  data () {
    return {
      currentPage: 1,
      perPage: 25,
      filter: null,
      fields: [
        'user',
        'type',
        'reference',
        'createdAt',
        'updatedAt',
        'status',
        'amount'
      ],
      selected: null,
      status: '',
      type: '',
      balance: 0,
      transactions: [],
      aggregate: 25,
      isBusy: false,
      selectedTransaction: {},
      transactionOpened: false,
      timeout: ''
    }
  },
  computed: {
    rows () {
      return this.aggregate
    }
  },
  watch: {
    status () {
      this.currentPage = 1
      this.$refs.table.refresh()
    },
    type () {
      this.$refs.table.refresh()
    },
    filter () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$refs.table.refresh()
        clearTimeout(this.timeout)
      }, 1000)
    }
  },
  methods: {
    formatDate (date) {
      if (date) return format(new Date(date), 'dd.MM.yyyy')
    },
    formatTime (time) {
      if (time) return (new Date(time)).toTimeString().split(' ')[0]
    },
    tableRowSelected (item) {
      this.selectedTransaction = item[0]
      this.transactionOpened = true
    },
    computeInitials (name) {
      if (!name) return ''
      const names = name.split(' ')
      let initials = ''
      for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0)
      }
      return initials
    },
    async providerFunc (ctx) {
      let query = `page=${ctx.currentPage}`
      query += this.type ? `&type=${this.type}` : ''
      query += this.status ? `&status=${this.status}` : ''
      query += this.filter ? `&filter=true&query=${this.filter}` : ''
      try {
        this.isBusy = true
        const { data } = await getTransactions(query)
        this.balance = data.total
        this.transactions = data.data
        this.aggregate = data.agreegate
        return this.transactions
      } catch (error) {
        return []
      } finally {
        this.isBusy = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.initials {
  background-color: #3FBBC2;
  color: white;
  padding: 13px;
  border-radius: 50%;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
<style>
.hidden_header {
  text-transform: uppercase;
}
</style>
