import { get } from './http'
import { LIEN, TRANSACTIONS } from '../utils/routes'

export const getTransactions = query => get(`${TRANSACTIONS}?${query}`)

export const getLienTransactions = query => get(`${LIEN}?${query}`)

export const getTransactionStatus = status =>
  get(`${TRANSACTIONS}?status=${status}`)

export const getTransactionPage = page => get(`${TRANSACTIONS}?page=${page}`)
